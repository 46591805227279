





















import Vue from 'vue';
import axios from 'axios';
import { Column, Config, Filter, PagingOptions, SmartTable, Sorting } from 'rey-vue-smarttable';
import { getAvailableLanguages } from '@/languages/i18n';
import LocalizationResource from '@/models/LocalizationResource';

export default Vue.extend({
  name: 'LocalizationEditor',
  props: ['resourceIds', 'categeory'],
  components: {
    SmartTable
  },
  data() {
    return {
      dynamicFieldPrefix: 'dynamic_',
      smartTableConfig: new Config(
        (row) => row.ResourceId,
        [
          new Column({
            title: this.$t('views.systemadmin.localizations.tableHeaders.resourceId').toString(),
            fieldName: 'ResourceId',
            fieldType: 'string',
            editable: () => false,
            cellStyling: () => ({
              'text-muted': true
            })
          }),
          new Column({
            title: this.$t('views.systemadmin.localizations.tableHeaders.category').toString(),
            fieldName: 'Category',
            fieldType: 'string',
            editable: () => false,
            hidden: this.categeory != undefined,
            defaultValue: this.categeory
          })
        ],
        new Sorting('resourceId', 'ascending'),
        new PagingOptions(25)
      ).withEfApiActions(axios, 'Resources'),
      selected: undefined as LocalizationResource | undefined
    };
  },

  created() {
    if (this.resourceIds?.length > 0) {
      let filter = new Filter('ResourceId', 'string', 'equals', [this.resourceIds.join('||')]);
      this.smartTableConfig.permanentFilters.push(filter);
    }

    if (this.smartTableConfig.columns) {
      this.smartTableConfig.columns = this.smartTableConfig.columns.filter(
        (c) => c.fieldName.indexOf(this.dynamicFieldPrefix) !== 0
      );
      getAvailableLanguages().forEach((language) => {
        const languageColumn = new Column({
          title: language.displayName,
          description: language.displayName,
          fieldName: this.dynamicFieldPrefix + language.key,
          fieldType: 'string',
          hidden: false,
          hiddenInAddDialog: true,
          converter: (value) => value
        });
        languageColumn.editable = () => true;
        this.smartTableConfig.columns?.push(languageColumn);
      });
    }
  },

  methods: {
    async reloadTable() {
      // @ts-ignore
      this.$refs.smartTable.reload(true);
      this.$emit('didUpdateLocalization');
    }
  }
});
